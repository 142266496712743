<template>
  <div>
    <v-dialog v-model="dialogOpen"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      max-width="600"
      @keydown.esc="close"
      style="height: 50px">
      <v-card tile
        color="white">
        <v-card-title class="d-flex"
          style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Povijest stolova</h2>
            <div class="ml-6">
              <v-menu v-model="datePickerMenu"
                :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable
                    :value="formatedDate"
                    label="Na datum"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearDate"></v-text-field>
                </template>
                <v-date-picker v-model="deletionDate"
                  no-title
                  scrollable
                  @input="filterByDate">
                </v-date-picker>
              </v-menu>
            </div>
            <v-spacer></v-spacer>
            <v-icon style="margin-top: -10px"
              large
              @click="close">mdi-close</v-icon>
          </v-row>
          <v-row v-if="this.totalAmount > 0"
            class="mt-1 mb-1">
            Ukupno: {{ this.totalAmount | money(100, "EUR") }}</v-row>
        </v-card-title>
        <v-card-text style="padding: 0; margin: 0; overflow-x: hidden; height: 600px">
          <v-row>
            <div cols="12"
              style="min-width: 100%">
              <v-col v-if="filteredTables.length === 0"
                class="ml-7">
                Nema rezultata za odabrani datum.
              </v-col>
              <v-expansion-panels v-else
                focusable>
                <v-expansion-panel v-for="(table, i) in filteredTables"
                  :key="i"
                  hover
                  tabindex="0"
                  style="border-radius: 0">
                  <v-expansion-panel-header height="200"
                    hide-actions>
                    <v-row>
                      <div style="min-width: 25%; padding: 5px 2px 0px 2px">
                        <div class="caption grey--text text-center">
                          Naziv stola
                        </div>
                        <div class="text-center">{{ table.name }}</div>
                      </div>

                      <div style="min-width: 25%; padding: 5px 2px 0px 2px">
                        <div class="caption grey--text text-center">
                          Datum i vrijeme
                        </div>
                        <div class="text-center">
                          {{ (table.delete_time / 1000) | local }}
                        </div>
                      </div>

                      <div style="min-width: 25%; padding: 5px 2px 0px 2px">
                        <div class="caption grey--text text-right">
                          Korisnik
                        </div>
                        <div class="text-right">{{ table.user_name }}</div>
                      </div>

                      <div class="d-flex justify-end"
                        style="
                          min-width: 25%;
                          padding: 5px 2px 0px 2px;
                          text-align: right;
                        ">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon
                              v-bind="attrs"
                              v-on="on"
                              @keypress.enter.stop="">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item v-for="(item, index) in table.tableOptions"
                              :key="index"
                              style="cursor: pointer"
                              @click="`${item.action(table)}`"
                              @keypress.enter.prevent="`${item.action(table)}`">
                              <v-list-item-title>{{
                                item.title
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item class="pa-0 ma-0">
                        <v-row>
                          <div style="min-width: 40%; padding: 10px 2px 0px 15px"
                            class="d-flex justify-space-between text-center text-subtitle-2">
                            Artikl
                          </div>
                          <div style="min-width: 20%; padding: 10px 2px 0px 5px"
                            class="d-flex justify-space-between text-center text-subtitle-2">
                            Količina
                          </div>
                          <div style="min-width: 20%; padding: 10px 2px 0px 5px"
                            class="d-flex justify-space-between text-center text-subtitle-2">
                            J. cijena
                          </div>
                          <div style="min-width: 20%; padding: 10px 2px 0px 5px"
                            class="d-flex justify-space-between text-right text-subtitle-2">
                            Cijena
                          </div>
                        </v-row>
                      </v-list-item>
                      <v-list-item v-for="(item, index) in table.items"
                        :key="index"
                        class="pa-0 ma-0">
                        <v-row class="pa-0 ma-0">
                          <div style="min-width: 40%; padding: 0px 2px 0px 0px"
                            class="d-flex justify-space-between text-center text-subtitle-2">
                            {{ item.name }}
                          </div>
                          <div class="d-flex justify-space-between text-right text-subtitle-2"
                            style="min-width: 20%; padding: 0px 2px 0px 5px">
                            {{ item.quantity }}
                          </div>
                          <div style="min-width: 20%; padding: 0px 2px 0px 5px"
                            class="d-flex justify-space-between text-center text-subtitle-2">
                            {{ item.unitPrice | money(100, "EUR") }}
                          </div>
                          <div class="d-flex justify-space-between text-right text-subtitle-2"
                            style="min-width: 20%; padding: 0px 2px 0px 5px">
                            {{ item.total | money(100, "EUR") }}
                          </div>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
      <v-overlay v-if="loading"
        :absolute="absolute"
        :opacity="opacity"
        :value="overlay"
        style="z-index: 100">
        <div v-if="loading"
          class="text-center"
          style="
            position: absolute;
            z-index: 101;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          ">
          <v-progress-circular indeterminate
            size="86"
            color="primary">
          </v-progress-circular>
        </div>
      </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import moment from 'moment'
// import { clone } from '@/plugins/utils'
import applicationSettings from '@/mixins/applicationSettings'
import state from '@/state'

export default {
  components: {
  },
  mixins: [applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'confirm',
    'confirmClose'
  ],
  data () {
    return {
      dialogOpen: false,
      loading: false,
      tables: [],
      filteredTables: [],
      location: {},
      lastVisible: {},
      listeners: [],
      multiplier: 0,
      filtersExpanded: false,
      absolute: true,
      opacity: 0.46,
      overlay: true,
      register: null,
      document: null,
      last_balance_timestamp: new Date(new Date().setHours(0, 0, 0, 0)),
      datePickerMenu: false,
      deletionDate: null,
      deletionDateObj: null,
      totalAmount: 0
    }
  },
  watch: {
    dialogOpen (val) {
      this.clearDate()
      this.totalAmount = 0
      this.deletionDate = moment(new Date()).format('YYYY-MM-DD')
      if (val === false) {
        this.tables = []
      }
    }
  },
  computed: {
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    formatedDate () {
      if (!this.deletionDate) return null
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.deletionDate))
    }
  },
  methods: {
    close () {
      this.dialogOpen = false
    },
    open () {
      this.loading = true
      this.getTables()
      this.dialogOpen = true
    },
    applyFilter () {
      this.lastVisible = {}
      this.tables = []
    },
    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    async getTables () {
      var tables = []
      this.tables = []

      df.collection(`location_orders_history/${state.getPointOfSale().id}/tables`)
        .where('items', '!=', [])
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              var table = item.data()

              table.tableOptions = [{ title: 'Vrati stol', action: this.returnTable }]

              const idx = tables.findIndex(t => t?.id === table.id)
              if (idx > -1 && tables[idx].delete_time < table.delete_time) {
                tables[idx] = table
              } else if (idx < 0) {
                tables.push(table)
              }
            })

            this.tables = tables.sort((a, b) => b.delete_time - a.delete_time)
            this.filteredTables = this.tables
            this.filterByDate()

            this.loading = false
          }
        })
    },
    onExpandFilters () {
      this.filtersExpanded = !this.filtersExpanded
      this.expandIcon = this.filtersExpanded ? 'mdi-minus' : 'mdi-plus'
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    returnTable (table) {
      this.confirm({
        title: 'Povrat stola',
        message: `Jeste li sigurni da želite vratiti stol ${table.name}?`,
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'DA',
          cancelText: this.$t('$vuetify.close')
        }
      }).then(async (resp) => {
        if (resp) {
          this.confirmClose()

          delete table.tableOptions
          const query = df.collection(`location_orders/${state.getPointOfSale().id}/tables`).orderBy('number', 'desc').limit(1)
          query.get()
            .then((documentSnapshots) => {
              if (documentSnapshots.docs && !documentSnapshots.docs.empty && documentSnapshots.docs.length > 0) {
                documentSnapshots.docs.forEach(async (item) => {
                  var tableNumber = item.data().number
                  table.number = tableNumber + 1

                  var tableRef = df.doc(`location_orders/${state.getPointOfSale().id}/tables/${table.id}`)
                  // TODO MAKNI
                  var tableTempRef = df.doc(`location_orders_temp_backup/${state.getPointOfSale().id}/tables/${table.id}`)
                  //
                  var tableHistoryRef = df.doc(`location_orders_history/${state.getPointOfSale().id}/tables/${table.history_id}`)
                  try {
                    await tableRef.set(table)
                    // TODO MAKNI
                    await tableTempRef.set(table)
                    //
                    await tableHistoryRef.delete()
                    this.close()
                  } catch (err) {
                    console.error('Error saving document: ', err)
                  }
                })
              } else {
                // TODO javi gresku i pokusajte ponovno
              }
            })
        }
      })
    },

    filterByDate () {
      this.totalAmount = 0
      this.filteredTables = []
      this.deletionDateObj = new Date(this.deletionDate)
      const pickerYear = this.deletionDateObj.getFullYear()
      const pickerMonth = this.deletionDateObj.getMonth()
      const pickerDate = this.deletionDateObj.getDate()
      this.tables.forEach(table => {
        const date = new Date(table.delete_time)
        if (date.getFullYear() === pickerYear && date.getMonth() === pickerMonth && date.getDate() === pickerDate) {
          this.filteredTables.push(table)
        }
      })
      this.filteredTables.forEach(table => {
        table.items.forEach(item => {
          this.totalAmount += item.total
        })
      })
    },

    clearDate () {
      this.deletionDate = null
      this.filteredTables = [...this.tables]
      this.totalAmount = 0
    }
  },
  async created () {
    const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
    this.register = register.data()
    this.location = state.getPointOfSale()
    this.last_balance_timestamp = await this.register.last_balance_timestamp
  },
  mounted () {
    this.getMultiplier()
  }
}
</script>

<style scoped>
.v-dialog__content>>>.my-custom-dialog {
  position: absolute;
  top: 50px;
}

.panel-container {
  margin-top: 1rem;
}

.text-wrap {
  width: min-content;
  margin: auto;
}

.panel-cell {
  padding: 0 1px 1px 0;
}

.active {
  background-color: var(--v-accent-darken3);
  color: white;
}

.label {
  font-size: 0.6rem;
  color: white;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
